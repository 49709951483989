<template>
	<div class="photographer-landing-page" role="main">
		<div class="headline" aria-labelledby="headline-title">
			<h5 id="headline-subtitle">
				TYLKO DLA FIZJOTERAPEUTÓW, KTÓRZY CHCĄ MIEĆ WIĘCEJ KLIENTÓW
			</h5>
			<h1 id="headline-title">
				Przełomowa Metoda Dla Fizjoterapeutów, Żeby Mieć Więcej Klientów
				W Ciągu 90 Dni, Gwarantowane!
			</h1>
			<p>
				Odbierz Bezpłatną 30-Minutową Konsultację, Żeby Poznać Dokładny
				Plan, Jak Zacząć Umawiać Więcej Sesji Fizjoterapeutycznych
				(Wartość rozmowy: 570zł, teraz za darmo)
			</p>
			<button
				class="small-scroller"
				@click="scrollToForm"
				aria-label="Zarezerwuj bezpłatną rozmowę"
			>
				ZAREZERWUJ BEZPŁATNĄ ROZMOWĘ
			</button>
		</div>

		<div class="problem" role="region" aria-labelledby="problem-title">
			<div class="without-photo">
				<h2 id="problem-title">
					W końcu… Sprawdzony I Skuteczny Sposób, Żeby Umawiać Więcej
					Sesji Fizjoterapeutycznych!
				</h2>
				<p>
					Masz problemy z trafieniem do nowych klientów na Twoje
					fizjoterapie? Nie wiesz, jak zrobić to legalnie i etycznie?
					A może już próbowałeś zająć się marketingiem, ale chciałbyś,
					żeby zaczął działać lepiej?<br /><br />Jeśli tak, to dobrze
					trafiłeś. Pomagamy fizjoterapeutom umawiać więcej wizyt i
					wypełnić kalendarz na kilka tygodni do przodu w najkrótszym
					możliwym czasie.<br /><br />Do tej pory pokazaliśmy wielu
					fizjoterapeutom, w jaki sposób mogą zacząć umawiać znacznie
					więcej nowych pacjentów, bez lat pracy, wielu prób i
					kosztownych błędów. Zapewniliśmy imponujące wyniki sobie i
					naszym klientom, i możemy zrobić to samo dla Ciebie.
				</p>
				<button
					class="big-scroller"
					@click="scrollToForm"
					aria-label="Umów się na bezpłatną rozmowę"
				>
					UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
				</button>
			</div>

			<div class="with-photo-left">
				<h2>
					Gotowy Plan, Żeby Umawiać Więcej Wizyt I Nie Wydawać Na To
					Fortuny
				</h2>
				<p>
					Pozyskanie nowych klientów na fizjoterapię może być znacznie
					łatwiejsze niż się spodziewasz. Większość fizjoterapeutów po
					prostu nie wie, co dokładnie zrobić, żeby mieć więcej
					klientów - przez co często muszą odkrywać to sami.<br /><br />My
					zrobiliśmy za Ciebie całą ciężką pracę, więc będziesz musiał
					po prostu podążać krok po kroku za naszym planem, a zanim
					się obejrzysz, będziesz miał znacznie więcej klientów.
				</p>
				<img src="./photo1.png" alt="Plan strategii dla fotografów" />
			</div>

			<div class="with-photo-right">
				<h2>86% Fizjoterapeutów Boi Się Konsekwencji Marketingu</h2>
				<p>
					Wszyscy wychodzą z założenia, że lepiej nie ryzykować. W
					końcu zakaz reklamy to zakaz reklamy. Okazuje się jednak, że
					istnieją w pełni legalne i etyczne sposoby na dotarcie do
					nowych klientów.<br /><br />Krok po kroku pokażemy Ci, co
					odkryliśmy, żebyś mógł dosłownie cały czas zajmować się
					fizjoterapią. Poznasz metodę, której użyli nasi klienci,
					dzięki której dosłownie mieli aż za dużo wizyt - i umawiali
					je na kilka tygodni naprzód.<br /><br />Zarezerwuj swoją
					konsultację teraz i zobacz, jak Ty możesz osiągnąć podobny
					efekt, i jak możesz zrobić to szybciej, niż kiedykolwiek się
					spodziewałeś.
				</p>
				<img
					src="./photo2.png"
					alt="Fotografowie zapełniający kalendarz sesjami"
				/>
			</div>

			<button
				class="small-scroller"
				@click="scrollToForm"
				aria-label="Umów się na bezpłatną rozmowę"
			>
				UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
			</button>
		</div>

		<div
			class="bulletpoints"
			role="region"
			aria-labelledby="bulletpoints-title"
		>
			<h2 id="bulletpoints-title">
				Oto Krótki Zarys Tego, Czego Dowiesz Się Podczas Bezpłatnej,
				30-Minutowej Konsultacji
			</h2>
			<ul>
				<li>
					<strong>Porozmawiamy O Twojej Sytuacji</strong
					><br />Opowiesz nam, jak wygląda Twoja aktualna sytuacja.
					Dzięki temu powiemy Ci, co dokładnie powinno zadziałać dla
					Ciebie.
				</li>
				<li>
					<strong
						>Sprawdzona Strategia Dla Fizjoterapeutów, Której
						Użyliśmy, Żeby Umawiać Więcej Wizyt</strong
					><br />Pokażemy Ci niesamowicie skuteczną strategię, której
					użyliśmy, żeby pozyskać klientów dla nas i dla innych
					fizjoterapeutów.
				</li>
				<li>
					<strong
						>Jak Stworzyć Stabilne I Długotrwałe Źródło Dobrze
						Płatnych Klientów W Ciągu 30 Dni Bez Wydawania Na To
						Fortuny</strong
					><br />
					Ten sposób to nie jest jakiś dziwny, internetowy „hack”,
					który przestanie działać trzy dni po naszej rozmowie.
					Otrzymasz długotrwałe i skuteczne źródło nowych klientów dla
					swojego gabinetu.
				</li>
				<li>
					<strong
						>Dlaczego To, Co Fizjoterapeuci Powtarzają O Etyce Jest
						Przestarzałe I Nieskuteczne</strong
					><br />Wielu fizjoterapeutów nikomu się nawet nie pokazuje i
					oczekuje, że klienci przyjdą do nich sami. Pokażemy Ci,
					dlaczego reklama to nie jest sprzedaż wbrew woli klienta,
					tylko aktywna pomoc.
				</li>
				<li>
					<strong
						>3 Kluczowe Trendy Wśród Fizjoterapeutów, Którzy Mają
						Kalendarz Pełen Klientów</strong
					><br />Dowiesz się, co robią najbardziej znane gabinety
					fizjoterapii, że klienci wybierają akurat ich.
				</li>
			</ul>
			<button
				class="big-scroller"
				@click="scrollToForm"
				aria-label="Umów się na bezpłatną rozmowę"
			>
				UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
			</button>
		</div>

		<div class="amplify" role="region" aria-labelledby="amplify-title">
			<div class="with-photo-left">
				<h2 id="amplify-title">
					Oszczędź Sobie Kosztownych Prób I Błędów, Używając Naszej
					Metody Na Pozyskanie Więcej Klientów
				</h2>
				<p>
					Dlaczego miałbyś spędzać dosłownie lata, próbując odkryć
					rozwiązanie tego problemu sam? Możesz oszczędzić ten czas i
					osiągnąć znacznie lepszy efekt, kiedy po prostu skorzystasz
					z porady eksperta i użyjesz naszej sprawdzonej metody na
					zdobycie więcej klientów. To działa dosłownie jak skrót,
					dający Ci bezpośredni dostęp do tego, co normalnie miałbyś
					dopiero za kilka lat.<br /><br />Jeśli faktycznie jesteś
					zdecydowany zacząć umawiać więcej sesji w najkrótszym
					możliwym czasie, mieć więcej możliwości, więcej klientów i
					mniej stresu, niż do tej pory, umów się na bezpłatną
					rozmowę, dopóki jest jeszcze dostępna.
				</p>
				<img
					src="./photo3.png"
					alt="Oszczędność czasu i zdobywanie klientów"
				/>
			</div>
			<button
				class="small-scroller"
				@click="scrollToForm"
				aria-label="Umów się na bezpłatną rozmowę"
			>
				UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
			</button>
		</div>

		<div
			class="bulletpoints"
			role="region"
			aria-labelledby="additional-bulletpoints-title"
		>
			<h2 id="additional-bulletpoints-title">
				Oto Jeszcze Kilka Rzeczy, Których Możesz Się Spodziewać Podczas
				Naszej Bezpłatnej Rozmowy
			</h2>
			<ul>
				<li>
					<strong
						>Czego Nigdy Nie Powinieneś Robić, Jeśli Chcesz Umawiać
						Więcej Wizyt</strong
					><br />Proszę, nigdy nie rób tego podczas swojej drogi do
					umawiania więcej wizyt, chyba, że chcesz pozostać z
					niewielką ilością klientów znacznie dłużej, niż to
					konieczne.
				</li>
				<li>
					<strong
						>Dlaczego 88% Fizjoterapeutów Przychodzi Do Nas Z Tym
						Samym Problemem - I Jak Go Rozwiązać</strong
					><br />Zobacz, jaką przewagę nad innymi zdobędziesz,
					rozwiązując go.
				</li>
				<li>
					<strong
						>Najważniejsza Umiejętność, Bez Której Nigdy Nie
						Będziesz Miał Wielu Klientów</strong
					><br />Wielu fizjoterapeutów nie potrafi skutecznie robić
					tej jednej rzeczy. Pokażemy Ci, co powinieneś umieć, żeby
					zacząć umawiać więcej sesji fizjoterapeutycznych, nawet
					mając mniej rozmów z klientami.
				</li>
				<li>
					<strong
						>Klucz Do Zapełnienia Swojego Kalendarza Wizytami Tak
						Szybko, Jak To Możliwe</strong
					><br />Jeden z najważniejszych elementów układanki, jaką
					jest umawianie wizyt. Jeśli go poznasz, będziesz miał
					ogromną przewagę nad konkurencją.
				</li>
			</ul>
			<button
				class="big-scroller"
				@click="scrollToForm"
				aria-label="Umów się na bezpłatną rozmowę"
			>
				UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
			</button>
		</div>

		<div class="guarantee" role="region" aria-labelledby="guarantee-title">
			<img src="./guarantee.png" alt="Gwarancja RMD Solutions" />
			<div class="without-photo">
				<h2 id="guarantee-title">
					Pobijemy Wyniki Twojej Najlepszej Kampanii W Ciągu 90 Dni
					Albo Nie Musisz Nam Nic Płacić
				</h2>
				<p>
					Jeśli po naszej rozmowie postanowisz z nami współpracować,
					zapewnimy Ci gwarancję. Nie chcemy, żebyś brał całe ryzyko
					na siebie, przejmiemy jego największą część.
					<br /><br />Jeśli z jakiegoś powodu nie będziemy w stanie
					poprawić Twoich aktualnych wyników, oddamy Ci całe nasze
					wynagrodzenie za naszą pracę. Bez żadnych drobnych druczków,
					klauzul ani ukrytych sztuczek.
				</p>
				<button
					class="big-scroller"
					@click="scrollToForm"
					aria-label="Umów się na bezpłatną rozmowę"
				>
					UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
				</button>
			</div>
		</div>

		<div class="cta" role="region" aria-labelledby="cta-form-title">
			<div class="without-photo" id="form">
				<h2 id="cta-form-title">
					Odbierz Swoją Bezpłatną, Niezobowiązującą 30-Minutową
					Konsultację (O wartości 570zł)
				</h2>
				<p>
					Podczas tej pozbawionej jakichkolwiek zobowiązań rozmowy,
					jeden z naszych ekspertów przedyskutuje z Tobą Twoją
					aktualną sytuację i powie Ci, co powinieneś zrobić, żeby
					zacząć mieć więcej klientów w najkrótszym możliwym
					czasie.<br /><br />
					Podczas tej rozmowy dowiesz się:
				</p>
				<ul>
					<li>
						Jak zacząć umawiać więcej fizjoterapii w jak najkrótszym
						czasie bez zmniejszania cen ani wydawania fortuny na
						marketing
					</li>
					<li>
						Jaki jest dokładny proces, którego użyliśmy, żeby pomóc
						fizjoterapeutom wypełnić ich kalendarz sesjami na kilka
						tygodni do przodu
					</li>
					<li>
						Największy problem, z którym fizjoterapeuci przychodzą
						do nas, który nie pozwala im pozyskać nowych klientów -
						nieważne, jak mocno starają się go rozwiązać!
					</li>
				</ul>
				<p>
					To jest TYLKO dla ludzi, którzy poważnie myślą o tym, żeby
					faktycznie zacząć umawiać więcej sesji i mieć więcej
					klientów, a miejsca są ściśle ograniczone. Podaj swoje dane
					poniżej, żeby uniknąć rozczarowania i zabezpieczyć swoją
					konsultację.
				</p>
				<form
					@submit.prevent="sendInfo"
					aria-labelledby="consultation-form-title"
					aria-describedby="consultation-form-instructions"
				>
					<h3 id="consultation-form-title" class="visually-hidden">
						Formularz konsultacji
					</h3>
					<p
						id="consultation-form-instructions"
						class="visually-hidden"
					>
						Wypełnij poniższy formularz, aby umówić się na bezpłatną
						konsultację.
					</p>

					<div class="input-container name-container">
						<label for="consult-name" class="visually-hidden"
							>Imię</label
						>
						<i class="fas fa-user" aria-hidden="true"></i>
						<input
							id="consult-name"
							type="text"
							class="name"
							placeholder="Imię"
							v-model="name"
							name="fields[name]"
							aria-label="Imię"
							autocomplete="given-name"
							required
							aria-required="true"
						/>
					</div>
					<div class="input-container email-container">
						<label for="consult-email" class="visually-hidden"
							>E-Mail</label
						>
						<i class="fas fa-envelope" aria-hidden="true"></i>
						<input
							id="consult-email"
							type="email"
							class="email"
							placeholder="E-Mail"
							v-model="email"
							name="fields[email]"
							aria-label="Adres email"
							autocomplete="email"
							required
							aria-required="true"
						/>
					</div>
					<div class="input-container phone-container">
						<label for="consult-phone" class="visually-hidden"
							>Telefon</label
						>
						<i class="fas fa-phone" aria-hidden="true"></i>
						<input
							id="consult-phone"
							type="text"
							class="phone"
							placeholder="Telefon"
							v-model="phone"
							name="fields[phone]"
							aria-label="Numer telefonu"
							autocomplete="tel"
							required
							aria-required="true"
						/>
					</div>
					<button
						type="submit"
						class="big-scroller"
						:disabled="loading"
						aria-label="Umów się na bezpłatną rozmowę"
					>
						<span v-if="!loading"
							>UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</span
						>
						<span v-else>Wysyłanie...</span>
					</button>
					<div
						class="warning"
						ref="warningRef"
						role="alert"
						aria-live="assertive"
						v-if="warningMessage"
					>
						{{ warningMessage }}
					</div>
				</form>
			</div>
		</div>

		<div class="ps" role="contentinfo" aria-labelledby="ps-title">
			<div class="without-photo">
				<h3 id="ps-title" class="visually-hidden">Postscript</h3>
				<p>
					P.S. Nadal tutaj jesteś?<br /><br />Cieszę się, że
					przejrzałeś całą naszą stronę. Dobry research jest naprawdę
					ważny.<br /><br />Pamiętaj, że ta rozmowa jest całkowicie
					bezpłatna i bez jakichkolwiek zobowiązań. Jeśli nie spodoba
					Ci się to, co usłyszysz, nie musisz umawiać się potem na nic
					innego.<br /><br />Chcemy dać Ci cały arsenał metod, jak
					pozyskać więcej klientów, umawiać więcej sesji i wypełnić
					swój kalendarz na kilka tygodni do przodu. Nikt Ci jednak
					nie każe się na nic konkretnego zdecydować - to Ty
					podejmiesz decyzję.<br /><br />Proszę Cię tylko, żebyś
					podjął ją po tej konsultacji, a nie przed nią.
				</p>
				<button
					class="big-scroller"
					@click="scrollToForm"
					aria-label="Umów się na bezpłatną rozmowę"
				>
					UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
				</button>
			</div>
		</div>
	</div>
</template>

  <script>
	import { inject, ref, reactive, toRefs } from "vue";
	import axios from "axios";

	export default {
		name: "FizjoLandingPage",
		setup() {
			document.title = "Fizjoterapeuci - Darmowa Sesja Strategiczna";
			const endpoint = inject("g_endpoint");

			const formData = reactive({
				name: "",
				email: "",
				phone: "",
			});

			const warningMessage = ref("");
			const warningRef = ref(null);

			const validateField = (condition, message) => {
				if (!condition) {
					warningMessage.value = message;
					return false;
				}
				return true;
			};

			const sendInfo = async () => {
				// Walidacja pól
				if (!validateField(formData.name.trim() !== "", "Podaj imię."))
					return;
				if (
					!validateField(
						/^\S+@\S+\.\S+$/.test(formData.email),
						"Podaj poprawny adres email."
					)
				)
					return;
				if (
					!validateField(
						/^\d{9}$/.test(formData.phone),
						"Numer telefonu powinien składać się z 9 cyfr."
					)
				)
					return;

				try {
					// Przygotowanie danych do wysyłki
					const dataToSend = {
						name: formData.name.trim(),
						email: formData.email.trim(),
						phone_number: formData.phone.trim(),
						company: "none",
						main_question: "none",
						message: "none",
					};

					const response = await axios.post(
						`${endpoint}messages/`,
						dataToSend,
						{
							withCredentials: true,
							headers: { "Content-Type": "application/json" },
						}
					);

					// Zresetowanie formularza po udanej wysyłce
					Object.keys(formData).forEach((key) => (formData[key] = ""));
					warningMessage.value = "Wiadomość wysłana poprawnie";
				} catch (error) {
					console.error("Błąd podczas wysyłania danych:", error);
					warningMessage.value = "Wiadomość nie została wysłana";
				}
			};

			const scrollToForm = () => {
				const formElement = document.getElementById("form");
				if (formElement) {
					formElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			return {
				...toRefs(formData),
				warningMessage,
				sendInfo,
				scrollToForm,
				warningRef,
			};
		},
	};
</script>

  <style lang="scss" scoped>
	@import "@/style/style.scss";

	/* Dodane style dla dostępności, nie zmieniają istniejących */
	.visually-hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}

	/* Pozostałe style pozostają bez zmian */
	.photographer-landing-page {
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		max-width: 1200px;
		margin: auto;
		gap: 50px;
		padding-top: 50px;

		img {
			user-select: none;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		li {
			margin: 0;
		}

		h5 {
			font-size: 1.4rem;
		}

		h2 {
			font-size: 3rem;
		}

		h1 {
			font-size: 3.5rem;
		}

		p,
		li {
			font-size: 1.3rem;
		}

		.gold {
			color: $gold-color;
		}

		button {
			background-color: $gold-color;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1.3rem;
			width: 100%;
			padding: 30px;
			cursor: pointer;
			border-radius: 1000px;
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: white;
				color: $gold-color;
			}
		}

		form {
			width: 75%;
			margin: auto;
			display: flex;
			flex-direction: column;

			.input-container {
				position: relative;
				width: 100%;
				margin-bottom: 20px;

				input,
				textarea {
					width: 100%;
					padding: 15px 10px 15px 40px;
					border-radius: 5px;
					border: 1px solid #ddd;
					font-size: 1.2rem;
				}

				textarea {
					resize: none;
					min-height: 120px;
				}

				i {
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					color: $gold-color;
					font-size: 1.5rem;
				}
			}
		}

		.headline {
			width: 80%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 50px;
			margin: auto;
			padding-bottom: 50px;

			button {
				width: 60%;
			}
		}

		.problem,
		.amplify,
		.guarantee,
		.cta,
		.ps {
			width: 90%;
			display: flex;
			flex-direction: column;
			gap: 50px;
			margin: auto;
			margin-bottom: 50px;

			button {
				width: 60%;
				margin: auto;
			}
		}

		.guarantee {
			margin-top: 50px;
			margin-bottom: 50px;

			img {
				width: 30%;
				border-radius: 5px;
				margin: auto;
			}
		}

		.without-photo {
			width: 70%;
			margin: auto;
			display: flex;
			flex-direction: column;
			gap: 30px;

			h2 {
				text-align: center;
				margin-bottom: 20px;
			}

			p,
			button {
				width: 75%;
				margin: auto;
			}

			ul {
				width: 75%;
				margin: auto;
				padding-left: 20px;

				li {
					margin-bottom: 20px;
				}
			}

			form button {
				width: 100%;
			}
		}

		.with-photo-left,
		.with-photo-right {
			width: 100%;
			margin: auto;
			display: grid;
			grid-template-rows: auto auto;
			gap: 30px;
			align-items: center;

			img {
				width: 500px;
				border-radius: 5px;
				grid-area: image;
				object-fit: cover;
			}

			h2 {
				grid-area: title;
			}

			p {
				grid-area: text;
			}
		}

		.with-photo-left {
			grid-template-columns: auto 1fr;
			grid-template-areas:
				"image title"
				"image text";
		}

		.with-photo-right {
			grid-template-columns: 1fr auto;
			grid-template-areas:
				"title image"
				"text image";
		}

		.bulletpoints {
			width: 90%;
			display: flex;
			flex-direction: column;
			gap: 50px;
			margin: auto;

			h2 {
				text-align: center;
			}

			ul {
				width: 70%;
				margin: auto;
				padding-left: 20px;

				li {
					margin-bottom: 20px;
				}
			}

			button {
				width: 70%;
				margin: auto;
			}
		}

		/* Responsive styles */
		@media (max-width: 768px) {
			padding-top: 0;
			padding-bottom: 0;

			h1 {
				font-size: 2.5rem;
			}

			h2 {
				font-size: 1.8rem;
			}

			h5 {
				font-size: 1.2rem;
			}

			p,
			li {
				font-size: 1.1rem;
			}

			button {
				padding: 15px;
			}

			form {
				width: 100%;
			}

			.headline {
				width: 100%;
				padding-bottom: 0;
				gap: 20px;

				h5 {
					display: none;
				}

				button {
					width: 100%;
				}
			}

			.problem,
			.amplify,
			.guarantee,
			.cta,
			.ps {
				width: 100%;

				button {
					width: 100%;
				}
			}

			.guarantee {
				img {
					width: 50%;
				}
			}

			.without-photo {
				width: 100%;
				gap: 20px;

				p,
				button {
					width: 100%;
				}

				ul {
					width: 100%;
				}

				form button {
					width: 100%;
				}
			}

			.with-photo-left,
			.with-photo-right {
				width: 100%;
				grid-template-rows: auto auto auto;

				img {
					width: 100%;
				}
			}

			.with-photo-left {
				grid-template-columns: auto;
				grid-template-areas:
					"image"
					"title"
					"text";
			}

			.with-photo-right {
				grid-template-columns: auto;
				grid-template-areas:
					"image"
					"title"
					"text";
			}

			.bulletpoints {
				width: 100%;

				ul {
					width: 100%;
				}

				button {
					width: 100%;
				}
			}
		}
	}
</style>