<template>
	<div class="photographer-optin-page" role="main">
		<!-- Sekcja nagłówkowa -->
		<div class="headline" aria-labelledby="headline-title">
			<div class="headline-left">
				<h5 id="headline-subtitle">
					UWAGA FOTOGRAFOWIE, KÓRYM BRAKUJE KLIENTÓW
				</h5>
				<h1 id="headline-title">
					Te 6 Błędów Często Uniemożliwia Fotografom Zdobycie Nowych
					Klientów
				</h1>
				<p>
					Dowiedz się w końcu, co powstrzymuje Cię przed zdobyciem
					większej ilości klientów na sesję zdjęciową i rozwiąż ten
					problem raz na zawsze
				</p>
			</div>

			<div class="headline-image" aria-hidden="true">
				<img
					src="./photographerEbookMockup.png"
					alt="Mockup ebooka dla fotografów"
					id="ebook"
				/>
			</div>

			<div class="headline-left-form">
				<form
					@submit.prevent="submitForm"
					aria-labelledby="form-title"
					aria-describedby="form-instructions"
				>
					<h2 id="form-title" class="visually-hidden">
						Formularz pobrania ebooka
					</h2>
					<p id="form-instructions" class="visually-hidden">
						Wypełnij poniższy formularz, aby pobrać darmowego
						ebooka.
					</p>

					<div class="input-container name-container">
						<label for="name" class="visually-hidden">Imię</label>
						<i class="fas fa-user" aria-hidden="true"></i>
						<input
							id="name"
							type="text"
							class="name"
							placeholder="Imię"
							v-model="name"
							name="fields[name]"
							aria-label="Imię"
							autocomplete="given-name"
							required
							aria-required="true"
						/>
					</div>

					<div class="input-container email-container">
						<label for="email" class="visually-hidden"
							>E-Mail</label
						>
						<i class="fas fa-envelope" aria-hidden="true"></i>
						<input
							id="email"
							type="email"
							class="email"
							placeholder="E-Mail"
							v-model="email"
							name="fields[email]"
							aria-label="Adres email"
							autocomplete="email"
							required
							aria-required="true"
						/>
					</div>

					<button
						type="submit"
						:disabled="loading"
						aria-label="Wyślij formularz"
					>
						<span v-if="!loading">WYŚLIJ MI TEN EBOOK!</span>
						<span v-else>Wysyłanie...</span>
					</button>
				</form>
			</div>
		</div>

		<!-- Sekcja "O ebooku" -->
		<div class="about" role="region" aria-labelledby="about-title">
			<h5 id="about-subtitle">
				Przez ściśle ograniczony czas możesz pobrać BEZPŁATNĄ kopię
				naszego najnowszego ebooka napisanego specjalnie dla fotografów…
			</h5>
			<h2 id="about-title">
				"6 Fatalnych Błędów Popełnianych Przez Fotografów, Które Nie
				Pozwalają Im Pozyskać Klientów
				<br />(I Jak Ich Uniknąć)"
			</h2>
			<div>
				<p>W tym ekskluzywnym ebooku pokazujemy:</p>
				<ul>
					<li>
						POWÓD #1, dlaczego fotografowie nie mogą zdobyć nowych
						klientów bez względu na to, czego by nie zrobili,
					</li>
					<li>
						Łatwa do odtworzenia METODA, która pozwoliła innym
						fotografom zapełnić kalendarz sesjami na kilka miesięcy
						do przodu,
					</li>
					<li>
						Nieoczywista PRAWDA o pozyskiwaniu nowych klientów na
						sesje zdjęciowe i dlaczego to może być o wiele
						łatwiejsze, niż sobie kiedykolwiek wyobrażałeś,
					</li>
					<li>…i wiele więcej!</li>
				</ul>
				<button
					@click="scrollUp"
					aria-label="Wyślij mi bezpłatny ebook"
				>
					WYŚLIJ MI BEZPŁATNY EBOOK
				</button>
			</div>
		</div>

		<!-- Sekcja CTA -->
		<div class="cta" role="region" aria-labelledby="cta-title">
			<h2 id="cta-title">
				Uzyskaj natychmiastowy dostęp do tego zaskakującego BEZPŁATNEGO
				ebooka już teraz!
			</h2>
			<p>
				Po prostu wypełnij formularz, a dostaniesz ten ebook
				bezpośrednio na swojego maila.
			</p>
			<button @click="scrollUp" aria-label="Wyślij mi bezpłatny ebook">
				WYŚLIJ MI BEZPŁATNY EBOOK
			</button>
		</div>
	</div>
</template>

  <script>
	import { ref } from "vue";
	import { useRouter } from "vue-router";
	import { useHead } from "@vueuse/head";

	export default {
		name: "PhotographerOptinPage",
		setup() {
			useHead({
				title: "6 Fatalnych Błędów Popełnianych Przez Fotografów",
				meta: [
					{
						name: "description",
						content: "6 Fatalnych Błędów Popełnianych Przez Fotografów",
					},
					{
						name: "keywords",
						content:
							"marketing, więcej klientów, jak pozyskać więcej klientów, fotograf, marketing dla fotografów, jak pozyskać klientów dla fotografów",
					},
				],
			});
			const name = ref("");
			const email = ref("");
			const loading = ref(false);
			const router = useRouter();

			const submitForm = async () => {
				loading.value = true;
				try {
					const formData = new FormData();
					formData.append("fields[name]", name.value);
					formData.append("fields[email]", email.value);
					formData.append("ml-submit", "1");

					const response = await fetch(
						"https://assets.mailerlite.com/jsonp/980239/forms/133831686872893090/subscribe",
						{
							method: "POST",
							body: formData,
							headers: {
								Accept: "application/json",
							},
						}
					);

					if (!response.ok) {
						throw new Error("Form submission failed");
					}

					router.push("/dziekujemy");
				} catch (error) {
					console.error("Error submitting form:", error);
					// Możesz dodać komunikat o błędzie tutaj, korzystając z ARIA live regions
				} finally {
					loading.value = false;
				}
			};

			const scrollUp = () => {
				const formElement = document.querySelector(".headline");
				if (formElement) {
					formElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			return {
				name,
				email,
				loading,
				submitForm,
				scrollUp,
			};
		},
	};
</script>

  <style lang="scss" scoped>
	@import "@/style/style.scss";

	.visually-hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}

	.photographer-optin-page {
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		max-width: 1200px;
		margin: auto;

		h5 {
			font-size: 1.4rem;
		}

		h2 {
			font-size: 3rem;
		}

		h1 {
			font-size: 3.5rem;
		}

		p,
		li {
			font-size: 1.3rem;
		}

		button {
			background-color: $gold-color;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1.3rem;
			width: 100%;
			padding: 30px;
			cursor: pointer;
			border-radius: 1000px;
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: white;
				color: $gold-color;
			}
		}

		.headline {
			display: grid;
			grid-template-columns: 1fr 1fr; /* Dwie równe kolumny */
			grid-template-rows: auto auto; /* Dwie wiersze, jeden dla nagłówka, jeden dla formularza */
			gap: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			/* Tekst nagłówka w pierwszej kolumnie */
			.headline-left {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
				display: flex;
				flex-direction: column;
			}

			/* Formularz w pierwszej kolumnie pod nagłówkiem */
			.headline-left-form {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 20px;

				form {
					width: 100%; /* Pełna szerokość formularza */
					display: flex;
					flex-direction: column;
				}

				.input-container {
					position: relative;
					width: 100%;
					margin-bottom: 20px;

					input {
						width: 100%;
						padding: 15px 10px 15px 40px;
						border-radius: 5px;
						border: 1px solid #ddd;
						font-size: 1.2rem;
					}

					i {
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						color: $gold-color;
						font-size: 1.5rem;
					}
				}
			}

			/* Obraz w drugiej kolumnie, rozciągający się na oba wiersze */
			.headline-image {
				grid-column: 2 / 3;
				grid-row: 1 / 3;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					max-width: 500px;
					height: auto;
				}
			}
		}

		.about {
			display: flex;
			flex-direction: column;
			padding-left: 200px;
			padding-right: 200px;
			padding-top: 100px;

			div {
				width: 80%;
				margin: auto;
				text-align: center;
			}

			h2,
			h5 {
				text-align: center;
				margin-top: 0;
				margin-bottom: 1rem;
			}

			ul {
				margin-bottom: 50px;

				li {
					margin-bottom: 1rem;
					text-align: left;
				}
			}
		}

		.cta {
			display: flex;
			flex-direction: column;
			padding-left: 300px;
			padding-right: 300px;
			padding-top: 100px;
			text-align: center;

			button {
				margin-top: 50px;
			}
		}

		/* Responsive styles */
		@media (max-width: 768px) {
			.headline {
				grid-template-columns: 1fr; /* Jedna kolumna na mobile */
				grid-template-rows: auto auto auto; /* Nagłówek, obraz, formularz ułożone pionowo */

				p,
				h5 {
					display: none;
				}

				h1 {
					text-align: center;
				}

				.headline-left,
				.headline-left-form,
				.headline-image {
					grid-column: 1 / 2;
					grid-row: auto; /* Pozwól elementom układać się pionowo */
				}

				.headline-image {
					img {
						width: 100%;
						max-width: 300px;
						margin-bottom: 20px;
					}
				}
			}

			h1 {
				font-size: 2.5rem;
			}

			h2 {
				font-size: 1.8rem;
			}

			h5 {
				font-size: 1.2rem;
			}

			p,
			li {
				font-size: 1.1rem;
			}

			button {
				padding: 15px;
			}

			.headline-left-form .input-container input {
				padding: 15px 10px 15px 40px;
			}

			.about {
				width: 100vw;
				padding: 10px;
				margin-top: 50px;
				transform: translateX(-5vw);

				div {
					width: 100%;
				}

				button {
					width: 90%;
					margin: auto;
				}
			}

			.cta {
				width: 100vw;
				padding: 10px;
				margin-top: 50px;
				transform: translateX(-5vw);

				div {
					width: 100%;
				}

				button {
					width: 90%;
					margin: auto;
				}
			}
		}
	}
</style>
