<template>
	<div class="photographer-optin-page" role="main">
		<div class="headline" aria-labelledby="headline-title">
			<div class="headline-left">
				<h5 id="headline-subtitle">
					UWAGA FIZJOTERAPEUCI, KTÓRYM BRAKUJE KLIENTÓW
				</h5>
				<h1 id="headline-title">
					Jak Zgodnie Z Prawem I Etyką Zawodową Skutecznie Zwiększyć
					Liczbę Klientów
				</h1>
				<p>
					Dowiedz się w końcu, co powstrzymuje Cię przed umawianiem
					większej ilości wizyt i rozwiąż ten problem raz na zawsze.
				</p>
			</div>

			<div class="headline-image" aria-hidden="true">
				<img
					src="./fizjoEbookMockup.png"
					alt="Mockup ebooka dla fotografów"
					id="ebook"
				/>
			</div>

			<div class="headline-left-form">
				<form
					@submit.prevent="submitForm"
					aria-labelledby="form-title"
					aria-describedby="form-instructions"
				>
					<h2 id="form-title" class="visually-hidden">
						Formularz pobrania ebooka
					</h2>
					<p id="form-instructions" class="visually-hidden">
						Wypełnij poniższy formularz, aby pobrać darmowego
						ebooka.
					</p>

					<div class="input-container name-container">
						<label for="name" class="visually-hidden">Imię</label>
						<i class="fas fa-user" aria-hidden="true"></i>
						<input
							id="name"
							type="text"
							class="name"
							placeholder="Imię"
							v-model="name"
							name="fields[name]"
							aria-label="Imię"
							autocomplete="given-name"
							required
							aria-required="true"
						/>
					</div>

					<div class="input-container email-container">
						<label for="email" class="visually-hidden"
							>E-Mail</label
						>
						<i class="fas fa-envelope" aria-hidden="true"></i>
						<input
							id="email"
							type="email"
							class="email"
							placeholder="E-Mail"
							v-model="email"
							name="fields[email]"
							aria-label="Adres email"
							autocomplete="email"
							required
							aria-required="true"
						/>
					</div>

					<button
						type="submit"
						:disabled="loading"
						aria-label="Wyślij formularz"
					>
						<span v-if="!loading">WYŚLIJ MI TEN EBOOK!</span>
						<span v-else>Wysyłanie...</span>
					</button>
				</form>
			</div>
		</div>

		<div class="about" role="region" aria-labelledby="about-title">
			<h5 id="about-subtitle">
				Przez ściśle ograniczony czas możesz pobrać BEZPŁATNĄ kopię
				naszego najnowszego ebooka napisanego specjalnie dla
				fizjoterapeutów…
			</h5>
			<h2 id="about-title">
				„Sprawdzona Metoda Dla Fizjoterapeutów, Jak Zgodnie Z Prawem I
				Etyką Zawodową Skutecznie Zwiększyć Liczbę Pacjentów
				<br />(I Dlaczego Praktycznie Żaden Fizjoterapeuta Jej Nie Zna)”
			</h2>
			<div>
				<p>W tym ebooku dowiesz się:</p>
				<ul>
					<li>
						Dlaczego wcale nie musisz łamać prawa, żeby mieć więcej
						klientów,
					</li>
					<li>
						Jaka jest nasza sprawdzona metoda dla fizjoterapeutów,
						jak zgodnie z prawem i etyką zawodową skutecznie
						zwiększyć liczbę pacjentów,
					</li>
					<li>
						Poznasz 6 kroków, żeby stworzyć stabilne źródło klientów
						w ciągu 30 dni,
					</li>
					<li>…i wiele więcej!</li>
				</ul>
				<button
					@click="scrollUp"
					aria-label="Wyślij mi bezpłatny ebook"
				>
					WYŚLIJ MI BEZPŁATNY EBOOK
				</button>
			</div>
		</div>

		<div class="cta" role="region" aria-labelledby="cta-title">
			<h2 id="cta-title">
				Uzyskaj natychmiastowy dostęp do tego BEZPŁATNEGO ebooka już
				teraz!
			</h2>
			<p>
				Po prostu wypełnij formularz, a dostaniesz ten ebook
				bezpośrednio na swojego maila.
			</p>
			<button @click="scrollUp" aria-label="Wyślij mi bezpłatny ebook">
				WYŚLIJ MI BEZPŁATNY EBOOK
			</button>
		</div>
	</div>
</template>

  <script>
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	export default {
		name: "fizjoOptinPage",
		setup() {
			document.title =
				"Jak Zgodnie Z Prawem I Etyką Zawodową Skutecznie Zwiększyć Liczbę Klientów";
			const name = ref("");
			const email = ref("");
			const loading = ref(false);
			const router = useRouter();

			const submitForm = async () => {
				loading.value = true;
				try {
					const formData = new FormData();
					formData.append("fields[name]", name.value);
					formData.append("fields[email]", email.value);
					formData.append("ml-submit", "1");

					const response = await fetch(
						"https://assets.mailerlite.com/jsonp/980239/forms/142074955080664370/subscribe",
						{
							method: "POST",
							body: formData,
							headers: {
								Accept: "application/json",
							},
						}
					);

					if (!response.ok) {
						throw new Error("Form submission failed");
					}

					router.push("/dziekujemy");
				} catch (error) {
					console.error("Error submitting form:", error);
				} finally {
					loading.value = false;
				}
			};

			const scrollUp = () => {
				const formElement = document.querySelector(".headline");
				if (formElement) {
					formElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			return {
				name,
				email,
				loading,
				submitForm,
				scrollUp,
			};
		},
	};
</script>

  <style lang="scss" scoped>
	@import "@/style/style.scss";

	.visually-hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}

	.photographer-optin-page {
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		max-width: 1200px;
		margin: auto;

		h5 {
			font-size: 1.4rem;
		}

		h2 {
			font-size: 3rem;
		}

		h1 {
			font-size: 3.5rem;
		}

		p,
		li {
			font-size: 1.3rem;
		}

		button {
			background-color: $gold-color;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1.3rem;
			width: 100%;
			padding: 30px;
			cursor: pointer;
			border-radius: 1000px;
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: white;
				color: $gold-color;
			}
		}

		.headline {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
			gap: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			.headline-left {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
				display: flex;
				flex-direction: column;
			}

			.headline-left-form {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 20px;

				form {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				.input-container {
					position: relative;
					width: 100%;
					margin-bottom: 20px;

					input {
						width: 100%;
						padding: 15px 10px 15px 40px;
						border-radius: 5px;
						border: 1px solid #ddd;
						font-size: 1.2rem;
					}

					i {
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						color: $gold-color;
						font-size: 1.5rem;
					}
				}
			}

			.headline-image {
				grid-column: 2 / 3;
				grid-row: 1 / 3;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					max-width: 500px;
					height: auto;
				}
			}
		}

		.about {
			display: flex;
			flex-direction: column;
			padding-left: 100px;
			padding-right: 100px;
			padding-top: 100px;

			div {
				width: 60%;
				margin: auto;
				text-align: center;
			}

			h2,
			h5 {
				text-align: center;
				margin-top: 0;
				margin-bottom: 1rem;
			}

			ul {
				margin-bottom: 50px;

				li {
					margin-bottom: 1rem;
					text-align: left;
				}
			}
		}

		.cta {
			display: flex;
			flex-direction: column;
			padding-left: 300px;
			padding-right: 300px;
			padding-top: 100px;
			text-align: center;

			button {
				margin-top: 50px;
			}
		}

		@media (max-width: 768px) {
			.headline {
				grid-template-columns: 1fr;
				grid-template-rows: auto auto auto;

				p,
				h5 {
					display: none;
				}

				h1 {
					text-align: center;
				}

				.headline-left,
				.headline-left-form,
				.headline-image {
					grid-column: 1 / 2;
					grid-row: auto;
				}

				.headline-image {
					img {
						width: 100%;
						max-width: 300px;
						margin-bottom: 20px;
					}
				}
			}

			h1 {
				font-size: 2.5rem;
			}

			h2 {
				font-size: 1.8rem;
			}

			h5 {
				font-size: 1.2rem;
			}

			p,
			li {
				font-size: 1.1rem;
			}

			button {
				padding: 15px;
			}

			.headline-left-form .input-container input {
				padding: 15px 10px 15px 40px;
			}

			.about {
				width: 100vw;
				padding: 10px;
				margin-top: 50px;
				transform: translateX(-5vw);

				div {
					width: 100%;
				}

				button {
					width: 90%;
					margin: auto;
				}
			}

			.cta {
				width: 100vw;
				padding: 10px;
				margin-top: 50px;
				transform: translateX(-5vw);

				div {
					width: 100%;
				}

				button {
					width: 90%;
					margin: auto;
				}
			}
		}
	}
</style>
