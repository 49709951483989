<template>
	<div id="footer" role="contentinfo">
		<div id="socials" aria-label="Nasze media społecznościowe">
			<a
				href="https://www.facebook.com/profile.php?id=61556516234527"
				target="_blank"
				aria-label="Facebook RMD Solutions"
				rel="noopener noreferrer"
			>
				<img
					src="../assets/facebook.png"
					alt="Facebook"
					width="40"
					height="40"
				/>
			</a>
			<a
				href="https://www.instagram.com/rmd.solutions/"
				target="_blank"
				aria-label="Instagram RMD Solutions"
				rel="noopener noreferrer"
			>
				<img
					src="../assets/instagram.png"
					alt="Instagram"
					width="40"
					height="40"
				/>
			</a>
			<a
				href="https://www.threads.net/@rmd.solutions"
				target="_blank"
				aria-label="Threads RMD Solutions"
				rel="noopener noreferrer"
			>
				<img
					src="../assets/threads.png"
					alt="Threads"
					width="40"
					height="40"
				/>
			</a>
			<a
				href="https://twitter.com/Rafal_RMD"
				target="_blank"
				aria-label="Twitter RMD Solutions"
				rel="noopener noreferrer"
			>
				<img
					src="../assets/x.png"
					alt="Twitter"
					width="40"
					height="40"
				/>
			</a>
			<a
				href="https://www.linkedin.com/company/rmd-solutions/"
				target="_blank"
				aria-label="LinkedIn RMD Solutions"
				rel="noopener noreferrer"
			>
				<img
					src="../assets/linkedin.png"
					alt="LinkedIn"
					width="40"
					height="40"
				/>
			</a>
		</div>
		<p>
			<span aria-hidden="true">©</span> Created by RMD Solutions.
			<time datetime="2023">2023</time> -
			<time datetime="2025">2025</time>
		</p>
	</div>
</template>

<script>
	export default {
		setup() {},
	};
</script>

<style lang="scss" scoped>
	@import "../style/style.scss";

	#footer {
		border-top: 1px solid $border-color2;
		background-color: $main-color;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		padding-top: 3%;
		padding-bottom: 3%;

		#socials {
			display: flex;
			margin-bottom: 20px;

			img {
				width: 40px;
				cursor: pointer;
				margin-right: 20px;
			}
		}
	}
</style>
