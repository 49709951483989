import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";

const g_endpoint = "https://rmdsolutions.eu:8002/api/";

const app = createApp(App);
const head = createHead();

app.provide("g_endpoint", g_endpoint);
app.use(router);
app.use(head);
app.mount("#app");
